var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-box" }, [
    _vm.back
      ? _c("div", { staticClass: "back-icon" }, [
          _c("i", {
            staticClass: "el-icon-arrow-left",
            on: { click: _vm.backHistory },
          }),
        ])
      : _vm._e(),
    _vm._v(" " + _vm._s(_vm.title) + " "),
    _vm.complete
      ? _c(
          "div",
          { staticClass: "back-complete", on: { click: _vm.handleConplete } },
          [_vm._v("完成")]
        )
      : _c(
          "div",
          { staticClass: "back-complete", on: { click: _vm.handleHome } },
          [
            _c("img", {
              staticStyle: { height: "1.6rem", "margin-top": ".7rem" },
              attrs: { src: require("@/assets/icon/home1.png") },
            }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }