<template>
  <div class="header-box">
    <div class="back-icon" v-if="back" >
      <i @click="backHistory" class="el-icon-arrow-left"></i>
    </div>
    {{title}}
    <div class="back-complete" v-if="complete" @click="handleConplete">完成</div>
    <div class="back-complete" v-else @click="handleHome"><img style="height:1.6rem;margin-top:.7rem" src="@/assets/icon/home1.png"></div>
  </div>
</template>
<script>
export default {
  props:['title','back',"complete"],
  data(){
    return {

    }
  },
  methods:{
    backHistory(){
      if(this.back&&this.back!=true&&this.back.indexOf('/')!=-1){
        let params=this.back.split('/')
        // console.log(params)
        this.$router.push({path:params[0],query:{type:params[1]}})
      }else if(this.back&&this.back!=true&&this.back.indexOf('/')==-1){
        this.$router.push({name:this.back})
      }else{
        this.$router.go(-1)
      }
     
    },
    handleConplete(){
      this.$router.push({name:this.complete})
    },
    handleHome(){
      this.$router.replace({name:'home'})
    }
  }
}
</script>
<style lang="scss" scoped>
.header-box{
 text-align: center;
 line-height:3rem;
 font-size: 1.2rem;
 position: relative;
 .back-icon{
  position: absolute;
  left:2.5%;
  font-size: 1.5rem;
 }
 .back-complete{
  position: absolute;
  right:2.5%;
  top:0px;
  font-size: .9rem;
  z-index: 2;
 }
}
</style>